import axios from 'axios';
import {
  clearLocalStorage,
  deleteLocalStorage,
  getLocalStorage,
} from '../storage';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = getLocalStorage('apiToken') && getLocalStorage('apiToken');
    config.baseURL = process.env.REACT_APP_BASE_URL;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

const handleUnauthorized = (response: any) => {
  // Navigate to the login page or perform any other desired action
  deleteLocalStorage('apiToken');
  window.location.href = '/';
};

axiosInstance.interceptors.response.use(
  async (response: any) => {
    return response?.data;
  },
  async function (error: any) {
    if (error.response && error.response.status === 401) {
      handleUnauthorized(error.response);
    }
    return Promise.reject(error);
  }
);
export { axiosInstance };
